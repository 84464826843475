export default [
    // Environment
    // {
    //     name: 'environmentMapTexture',
    //     type: 'cubeTexture',
    //     path:
    //     [
    //         '/textures/environmentMaps/0/px.jpg',
    //         '/textures/environmentMaps/0/nx.jpg',
    //         '/textures/environmentMaps/0/py.jpg',
    //         '/textures/environmentMaps/0/ny.jpg',
    //         '/textures/environmentMaps/0/pz.jpg',
    //         '/textures/environmentMaps/0/nz.jpg'
    //     ]
    // },
    // Works
    {   
        name: 'astanaexpo',
        type: 'texture',
        path: '/textures/works/astanaexpo.jpg'
    },
    {   
        name: 'etb',
        type: 'texture',
        path: '/textures/works/etb.jpg'
    },
    {   
        name: 'freiburg',
        type: 'texture',
        path: '/textures/works/freiburg.jpg'
    },
    {   
        name: 'kehl',
        type: 'texture',
        path: '/textures/works/kehl.jpg'
    },
    {   
        name: 'moscase1',
        type: 'texture',
        path: '/textures/works/moscase1.jpg'
    },
    {   
        name: 'moscase2',
        type: 'texture',
        path: '/textures/works/moscase2.jpg'
    },
    {   
        name: 'nn',
        type: 'texture',
        path: '/textures/works/nn.jpg'
    },
    {   
        name: 'pavilionkarsruhe',
        type: 'texture',
        path: '/textures/works/pavilionkarsruhe.jpg'
    },
    {   
        name: 'petriplatz',
        type: 'texture',
        path: '/textures/works/petriplatz.jpg'
    },
    {   
        name: 'photography',
        type: 'texture',
        path: '/textures/works/photography.jpg'
    },
    {   
        name: 'raw',
        type: 'texture',
        path: '/textures/works/raw.jpg'
    },
    {   
        name: 'rkm',
        type: 'texture',
        path: '/textures/works/rkm.jpg'
    },
    {   
        name: 'tischtennis',
        type: 'texture',
        path: '/textures/works/tischtennis.jpg'
    },
    {   
        name: 'waldsee',
        type: 'texture',
        path: '/textures/works/waldsee.jpg'
    },
    {   
        name: 'warhammer',
        type: 'texture',
        path: '/textures/works/warhammer.jpg'
    },
    {   
        name: 'woodworking',
        type: 'texture',
        path: '/textures/works/woodworking.jpg'
    },
    // Stars
    // {   
    //     name: 'star',
    //     type: 'texture',
    //     path: '/textures/particles/5.png'
    // },
    // Post Processing
    // {   
    //     name: 'interfaceNormalMap',
    //     type: 'texture',
    //     path: 'textures/postProcessing/interfaceNormalMap.png'
    // }
    // 3D Models
    // {
    //     name: 'damagedHelmet',
    //     type: 'gltfModel',
    //     path: 'models/DamagedHelmet/glTF/DamagedHelmet.gltf'
    // }
]